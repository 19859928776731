import * as React from 'react'

type CustomRadarDotProps = {
  colour: string
  cx?: string | number
  cy?: string | number
}

export const CustomRadarDot: React.VFC<CustomRadarDotProps> = (props) => {
  const { colour, cx, cy } = props

  return (
    <circle
      className="recharts-dot recharts-radar-dot"
      cx={cx}
      cy={cy}
      fill={colour}
      r="3"
      stroke={colour}
    ></circle>
  )
}
